import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = [ "comment"]

  connect() {
    let show_comment = this.data.has('quality') && this.data.get('quality') === 'low_quality'
    this.toggleComment(show_comment)
  }

  doQualityChange(event) {
    let quality = event.target.value
    this.toggleComment(quality === 'low_quality')
  }

  toggleComment(show_comment) {
    if (show_comment) {
      $(this.commentTarget).removeClass('d-none')
    } else {
      $(this.commentTarget).addClass('d-none')
    }
  }
}
